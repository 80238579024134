import React from "react"
import { Link } from "gatsby"
import SideBarMenu from "../sidebar/sidebar"
import Sticky from "react-sticky-el"
import "./header.css"

import Logo from "../../images/logo.png"
import Upload from "../../images/001-cloud-computing.svg"
import Create from "../../images/003-edit.svg"
import Share from "../../images/002-share.svg"

const Header = () => (
	<header>
		<Sticky
			className="sticky-wrapper"
			stickyClassName="is-sticky"
			stickyStyle={{ transform: "unset", zIndex: "2" }}
		>
			<div className="header">
				<div className="container">
					<div className="row justify-content-between align-items-center">
						<div className="col-9 col-md-4">
							<div className="header-logo">
								<Link to="/">
									<div className="nav-brand">
										<img className="logo" src={Logo} alt="Buy Now Logo" />
									</div>
								</Link>
							</div>
						</div>
						<div className="col-2 col-md-8 d-flex justify-content-end align-items-center ml-auto">
							<div className="action-menus float-left mb-0">
								<ul className="list-unstyled d-flex mb-0 mr-3">
									<li>
										<a
                                            //href="https://prints.buynow.io/#!sellnowioPrintfulWizard;src=buynow.io;choice=PRINTFUL_PRODUCT_SHOW_IMAGES"
                                            //href="http://localhost:9999/#!sellnowioPrintfulWizard;src=buynow.io;choice=PRINTFUL_PRODUCT_SHOW_IMAGES"
                                            href={`${process.env.GATSBY_PRINTS_BUYNOW_HOST}/#!sellnowioPrintfulWizard;src=buynow.io;choice=PRINTFUL_PRODUCT_SHOW_IMAGES`}
                                            target="prints.buynow.io"
                                            //rel="noopener noreferrer"
                                        >
											<img
												className="img-fluid mb-0"
												src={Upload}
												width={20}
												height={20}
												alt="Upload"
											/>
											<span className="ml-2 mr-2 d-none d-sm-inline">
												Upload
											</span>
										</a>
									</li>
									<li>
										<a
                                            //href="https://prints.buynow.io/#!sellnowioPrintfulWizard;src=buynow.io;choice=PRINTFUL_PRODUCT_MENU"
                                            href="http://localhost:9999/#!sellnowioPrintfulWizard;src=buynow.io;choice=PRINTFUL_PRODUCT_MENU"
                                            href={`${process.env.GATSBY_PRINTS_BUYNOW_HOST}/#!sellnowioPrintfulWizard;src=buynow.io;choice=PRINTFUL_PRODUCT_MENU`}
                                            target="prints.buynow.io"
                                            //rel="noopener noreferrer"
                                        >
											<img
												className="img-fluid mb-0"
												src={Create}
												width={18}
												height={18}
												alt="Create"
											/>
											<span className="ml-2 mr-2 d-none d-sm-inline">
												Create
											</span>
										</a>
									</li>
									<li>
										<a
                                            //href="https://prints.buynow.io/#!sellnowioPrintfulWizard;src=buynow.io;choice=PRINTFUL_SHARE_PRODUCT"
                                            //href="http://localhost:9999/#!sellnowioPrintfulWizard;src=buynow.io;choice=PRINTFUL_SHARE_PRODUCT"
                                            href={`${process.env.GATSBY_PRINTS_BUYNOW_HOST}/#!sellnowioPrintfulWizard;src=buynow.io;choice=PRINTFUL_SHARE_PRODUCT`}
                                            target="prints.buynow.io"
                                            //rel="noopener noreferrer"
                                        >
											<img
												className="img-fluid mb-0"
												src={Share}
												width={20}
												height={20}
												alt="Share"
											/>
											<span className="ml-2 mr-2 d-none d-sm-inline">
												Share
											</span>
										</a>
									</li>
								</ul>
							</div>
							<SideBarMenu />
						</div>
					</div>
				</div>
			</div>
		</Sticky>
	</header>
)

export default Header
