import React from "react"
// import { Link } from "gatsby"

import placeholder from "../../images/001-placeholder.svg"
import call from "../../images/002-call.svg"
import mail from "../../images/003-mail.svg"
import { Link } from "gatsby"
import fb from "../../images/002-facebook.svg"
import twitter from "../../images/003-twitter.svg"
import linkedin from "../../images/linkedin.svg"
import instagram from "../../images/001-instagram.svg"
import pinterest from "../../images/004-pinterest.svg"

const footer = props => {
	return (
		<div id="contact">
			<footer className="component bg-dark-blue pb-0">
				<div className="container">
					<div className="row">
						<div className="col-md-3">
							<div className="content">
								<h5 className="text-white mb-3">Contact</h5>
								<ul className="list-unstyled footer-contact-info">
									<li>
										<img
											className="mr-2"
											src={placeholder}
											width={18}
											height={18}
											alt="Address"
										/>
										<span>Boulder Co.</span>
									</li>
									<li>
										<img
											className="mr-2"
											src={call}
											width={16}
											height={16}
											alt="Number"
										/>
										<a href="tel:5553311152">555-331-1152</a>
									</li>
									<li>
										<img
											className="mr-2"
											src={mail}
											width={16}
											height={16}
											alt="Email"
										/>
										<a href="mailto:argo@buynow.io">argo@buynow.io</a>
									</li>
								</ul>
							</div>
						</div>
						<div className="col-md-3">
							<div className="content">
								<h5 className="text-white mb-3">Links</h5>
								<ul className="list-unstyled footer-links">
									<li>
										<Link to="/">Home</Link>
									</li>
									<li>
										<Link to="/#about">About</Link>
									</li>
									<li>
										<Link to="/#sell-everywhere">Product</Link>
									</li>
									<li>
										<Link to="/#clients">Clients</Link>
									</li>
									<li>
										<a
											//href="https://buynow.io/featuredmerch"
											//href="http://localhost:8080/featuredmerch"
                                            href={`${process.env.GATSBY_BUYNOW_HOST}/featuredmerch`}
											target="buynow.io"
											//rel="noopener noreferrer"
										>
											Featured
										</a>
									</li>
									<li>
										<a
											//href="https://buynow.io/trending"
											//href="http://localhost:8080/trending"
                                            href={`${process.env.GATSBY_BUYNOW_HOST}/trending`}
											target="buynow.io"
											//rel="noopener noreferrer"
										>
											Trending
										</a>
									</li>
									<li>
										<a
											//href="https://buynow.io/campaigns"
											//href="http://localhost:8080/campaigns"
                                            href={`${process.env.GATSBY_BUYNOW_HOST}/campaigns`}
											target="buynow.io"
											//rel="noopener noreferrer"
										>
											Campaign
										</a>
									</li>
									<li>
										<Link to="/#contact">Contact</Link>
									</li>
								</ul>
							</div>
						</div>
						<div className="col">
							<div className="content">
								<h5 className="text-white mb-3">Social</h5>
								<ul className="list-unstyled footer-social d-flex">
									<li>
										<a
											href="https://www.facebook.com/buynow.io.1"
											target="_blank"
											rel="noopener noreferrer"
										>
											<img src={fb} width={24} height={24} alt="Facebook" />
										</a>
									</li>
									<li>
										<a
											href="https://www.twitter.com/buynowio"
											target="_blank"
											rel="noopener noreferrer"
										>
											<img src={twitter} width={24} height={24} alt="Twitter" />
										</a>
									</li>
									<li>
										<a
											href="https://www.linkedin.com/in/chrisargo"
											target="_blank"
											rel="noopener noreferrer"
										>
											<img
												src={linkedin}
												width={24}
												height={24}
												alt="Linkedin"
											/>
										</a>
									</li>
									<li>
										<a
											href="https://www.instagram.com/buynowio"
											target="_blank"
											rel="noopener noreferrer"
										>
											<img
												src={instagram}
												width={24}
												height={24}
												alt="Instagram"
											/>
										</a>
									</li>
									<li>
										<a
											href="https://www.pinterest.com/buynowio"
											target="_blank"
											rel="noopener noreferrer"
										>
											<img
												src={pinterest}
												width={24}
												height={24}
												alt="Pinterest"
											/>
										</a>
									</li>
								</ul>
							</div>
						</div>
					</div>
				</div>
				<div id="copyright" className="py-4 mt-4">
					<div className="container">
						<p className="text-white mb-0">
							©&nbsp;2020&nbsp;Designed and Powered by&nbsp;
							<a href="http://webriq.com/">WebriQ</a>
						</p>
					</div>
				</div>
			</footer>
		</div>
	)
}

export default footer
